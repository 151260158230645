import { Theme_ThemeType } from "@100mslive/types-prebuilt";
import {
  argbFromHex,
  hexFromArgb,
  TonalPalette,
} from "@material/material-color-utilities";
import axios from "axios";
import Haikunator from "haikunator";
import cookies from "js-cookie";
import _, { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import queryString from "query-string";
import "moment-timezone";
import { analyseColorPalette } from "./components/Prebuilt/helpers/utils";
import {
  defaultDarkColorPalette,
  defaultLightColorPalette,
} from "./data/colorPalette";
import {
  defaultLayoutColorPalette,
  defaultLightLayoutColorPalette,
} from "./data/defaultLayout";
import { createGetURL } from "./helpers/utils";
import {
  DEFAULT_ASPECT_RATIO,
  HLS_DEFAULT_VIDEO_LAYER_CONFIG,
  ScreenShareQuality,
  TILE_SHAPE,
  VIDEO_QUALITY_FOR_POLICY,
  VideoQuality,
} from "./constants";
import domain from "./js-cookies.config";

export const fetchDataFromPresignedUrl = async url => {
  const resp = await axios.get(url);
  return resp;
};

export const getLocalZone = () => {
  const zoneAbbr = new Date()
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];
  return zoneAbbr;
};

export const formattedDate = date => {
  if (!date) {
    return "-";
  }
  const zone = moment.tz.guess(true);

  return moment(date).tz(zone).format("MMM D YYYY, HH:mm:ss");
};

export const haikunateText = (text, tokenLength = 6, delimiter = "") => {
  const haikunator = new Haikunator();
  return `${text}${delimiter}${haikunator.haikunate({
    tokenLength: tokenLength,
    delimiter: delimiter,
  })}`;
};

export const formatFileSize = bytes => {
  const units = ["B", "KB", "MB", "GB", "TB"];
  let i;
  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }
  return `${bytes.toFixed(2)} ${units[i]}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const isValidObjectID = (id, type = "mongo") => {
  if (!id) {
    return true;
  }
  let pattern = /.*/;
  switch (type) {
    case "mongo":
      pattern = /^[0-9a-fA-F]{24}$/;
      break;
    case "uuid":
      pattern =
        /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
      break;
    default:
      console.error("Invalid ID type specified");
  }

  return pattern.test(id);
};

export const secondsToHMS = seconds => {
  if (!seconds) {
    return "-";
  }

  const duration = moment.duration(seconds, "seconds");
  const h = String(
    Math.floor(duration.asDays()) * 24 + duration.hours()
  ).padStart(2, "0");
  const m = String(duration.minutes()).padStart(2, "0");
  const s = String(duration.seconds()).padStart(2, "0");
  return `${h}:${m}:${s}`;
};

export const layerSortingComparator = (a, b) => {
  const diff = b.width - a.width;
  return diff === 0 ? b.videoBitrate - a.videoBitrate : diff;
};

export const uniqueObjects = (array, key) =>
  _.uniqWith(array, (a, b) => a[key] === b[key]);

/* eslint-disable no-useless-escape */
export const slugify = text => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
};

const createCookieKey = () => {
  return `authUser${
    process.env.REACT_APP_ENV === "prod" ? "" : `-${process.env.REACT_APP_ENV}`
  }`;
};

const clearLocalStorage = () => {
  const workspaceIDs = getWorkspaceIDsfromLocal();
  localStorage.clear();
  saveWorkspaceIDtoLocal(workspaceIDs);
};
//eslint-disable-next-line complexity
const getReferrer = searchParams => {
  const cookieKey = "initialReferrer";
  let referrerCookieObject = {};
  try {
    referrerCookieObject = JSON.parse(cookies.get(cookieKey));
  } catch (e) {}
  let referrer = window.utm_params
    ? window.utm_params.referrer
    : "" || document.referrer;
  if (
    referrer &&
    (referrer.includes("accounts.google.com") ||
      referrer.includes("github.com/login/oauth/"))
  ) {
    referrer = document.referrer;
  }
  if (searchParams) {
    if (referrer) {
      searchParams.referrer = referrer;
    }
    for (let utm_params of utm_keys) {
      referrerCookieObject[utm_params] =
        searchParams[utm_params] || referrerCookieObject[utm_params];
    }
  }
  const expirationTime = process.env.COOKIE_EXPIRY || 90;
  cookies.remove(cookieKey);
  cookies.set(cookieKey, JSON.stringify(referrerCookieObject), {
    expires: expirationTime,
    // domain,
    path: "/",
  });
  return referrerCookieObject;
};

export const currentUser = () => {
  let authUser;
  try {
    const cookieKey = createCookieKey();
    authUser = JSON.parse(cookies.get(cookieKey));
  } catch {
    authUser = null;
  }
  return authUser;
};
export const currentWorkspaceHipaa = () => {
  const userCookie = currentUser();
  if (!isAdmin()) {
    return userCookie?.isHipaa || false;
  }
  return false;
};

export const saveCurrentUser = (data, expiryTime = 99) => {
  const cookieValue = JSON.stringify(data);
  const cookieKey = createCookieKey();
  const expirationTime = expiryTime;
  localStorage.setItem(cookieKey, cookieValue);
  cookies.set(cookieKey, cookieValue, { expires: expirationTime, domain });
};

export const removeCurrentUser = () => {
  const cookieKey = createCookieKey();
  clearLocalStorage();
  cookies.remove(cookieKey, { domain });
};

export const convertTileAspectRatio = value => {
  const shapeMap = {
    SQUARE: "1-1",
    LANDSCAPE: "4-3",
    WIDE: "16-9",
  };
  if (/[A-Z]/.test(value)) {
    return shapeMap[value];
  }
  return value;
};

export const isDeactivatedTemplateRoom = ({ all_templates, room }) => {
  const tempTemplate = all_templates.find(
    template => room.template_id === template.id
  );
  if (isEmpty(tempTemplate) || typeof tempTemplate === "undefined") {
    room["isDeletedTemplate"] = false;
  } else {
    room["isDeletedTemplate"] = true;
  }

  return room;
};

export const isAdmin = () => {
  return Boolean(currentUser()?.admin);
};

export const signedUpUser = () => {
  return Boolean(currentUser()?.show_onboarding);
};

export const getAPIURL = (
  endpoint,
  params = {},
  base = process.env.REACT_APP_BACKEND_API
) => {
  let resultURL = "";
  const user = currentUser();

  if (isAdmin()) {
    resultURL = createGetURL(`${base}adminview/${endpoint}`, {
      customer_id: user?.customer?.customer_id || "",
      customer_email: user?.customer?.email || "",
      user_id: user?.customer?.user_id || "",
      workspace_id: user?.customer?.workspace_id || "",
      ...params,
    });
  } else {
    resultURL = createGetURL(base + endpoint, {
      ...params,
    });
  }
  return resultURL;
};

export const isOnBoarding = () => {
  return currentUser()?.show_onboarding;
};

export const utm_keys = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_keyword",
  "utm_term",
  "utm_id",
  "referrer",
  "ref",
  "utm_content",
];

export const getUtmParams = () => {
  if (!_.isEmpty(window.utm_params)) {
    return window.utm_params;
  }
  const searchParams = queryString.parse(window.location.search);
  window.utm_params = _.pick(searchParams, utm_keys);

  const hashParams = queryString.parse(window.location.hash);
  if (_.isEmpty(window.utm_params) && hashParams.id_token) {
    // redirected from google oauth
    const stateParams = JSON.parse(decodeURIComponent(hashParams.state));
    window.utm_params = _.pick(stateParams, utm_keys);
  } else if (_.isEmpty(window.utm_params) && searchParams.code) {
    // redirected from github oauth
    const stateParams = JSON.parse(
      decodeURIComponent(searchParams.state || "{}")
    );
    window.utm_params = _.pick(stateParams, utm_keys);
  }

  window.utm_params = {
    ...window.utm_params,
    ...getReferrer(searchParams),
  };

  return window.utm_params;
};

const listOfTrackersForSignUp = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_keyword",
  "utm_term",
  "ref",
  "utm_content",
];

export const getTrackersForSignUp = () => {
  const utmParams = getUtmParams();
  const trackers = {};
  for (const i of listOfTrackersForSignUp) {
    if (utmParams[i]) {
      trackers[i] = utmParams[i];
    }
  }
  if (utmParams.referrer) {
    trackers["ref"] = utmParams.referrer;
  }
  return trackers;
};

export async function getFirebaseDeeplink({
  link,
  socialTitle,
  socialImage = "",
  socialDescription = "",
}) {
  const response = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_KEY}`,
    {
      dynamicLinkInfo: {
        domainUriPrefix: "https://flutterhms.page.link",
        link: link,
        androidInfo: {
          androidPackageName: "live.hms.flutter",
        },
        iosInfo: {
          iosBundleId: "live.100ms.flutter",
          iosCustomScheme: "live.100ms.flutter",
          iosAppStoreId: "1576541989",
        },
        navigationInfo: {
          enableForcedRedirect: false,
        },
        socialMetaTagInfo: {
          socialTitle: socialTitle,
          socialDescription: socialDescription,
          socialImageLink: socialImage,
        },
      },
      suffix: {
        option: "UNGUESSABLE",
      },
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response;
}

export function createRoomLinks({ roomLinks }) {
  const roleName = Object.keys(roomLinks);
  const roomLink = Object.values(roomLinks);
  const arr = [];
  for (let i = 0; i <= roleName.length; i++) {
    const temp = { room: roleName[i], roomLink: roomLink };
    arr.push(temp);
  }
  return arr;
}

export function getRegisteredUser() {
  return JSON.parse(localStorage.getItem("user"));
}

function aspect_ratio(val, lim) {
  var lower = [0, 1];
  var upper = [1, 0];

  //eslint-disable-next-line
  while (true) {
    var mediant = [lower[0] + upper[0], lower[1] + upper[1]];

    if (val * mediant[1] > mediant[0]) {
      if (lim < mediant[1]) {
        return upper;
      }
      lower = mediant;
    } else if (val * mediant[1] === mediant[0]) {
      if (lim >= mediant[1]) {
        return mediant;
      }
      if (lower[1] < upper[1]) {
        return lower;
      }
      return upper;
    } else {
      if (lim < mediant[1]) {
        return lower;
      }
      upper = mediant;
    }
  }
}

export const calculateTotalPixels = (resolution, ratio) => {
  const res = calculateLayerDimensions({
    aspectRatio: ratio,
    layerRes: resolution,
  });
  return res.width * res.height;
};

export const getLayerBitRate = (res, aspectRatio) => {
  //reverse the array as we wanna see traverse from lowest to highest possible res
  const arr = [
    ...HLS_DEFAULT_VIDEO_LAYER_CONFIG.sort((b, a) => {
      return b.value - a.value;
    }),
  ];
  //find index of layer which is just greater than our resolution in total number of pixels
  const layerIndex = arr.findIndex(layer => {
    if (
      calculateTotalPixels(layer.value, "16:9") >=
      calculateTotalPixels(res, aspectRatio)
    ) {
      return true;
    } else {
      return false;
    }
  });
  let layer = {
    defaultVideoBitrate: 0,
    minVideoBitRate: 0,
    maxVideoBitRate: 0,
  };
  //if layerIndex exists return layer with correct value else return 0
  if (layerIndex >= 0) {
    layer.defaultVideoBitrate = arr[layerIndex].defaultVideoBitrate;
    layer.minVideoBitRate = arr[layerIndex].minVideoBitRate;
    layer.maxVideoBitRate = arr[layerIndex].maxVideoBitRate;
  }
  return layer;
};

export const createLayerObject = (layer, aspectRatio) => {
  // get bitrate for total pixels
  const bitRate = getLayerBitRate(
    layer?.value,
    aspectRatio
  )?.defaultVideoBitrate;

  return {
    width: calculateLayerDimensions({
      aspectRatio,
      layerRes: layer?.value,
    })["width"],

    height: calculateLayerDimensions({
      aspectRatio,
      layerRes: layer?.value,
    })["height"],
    videoBitrate: layer?.videoBitrate ? layer?.videoBitrate : bitRate,
    audioBitrate: layer?.defaultAudioBitrate,
  };
};

export const isCustomAspectRatio = str => {
  return !TILE_SHAPE.some(item => item.type === str);
};

export function calculateAspectRatio({ width, height }) {
  //since the first value for 854/480, 65 will be 71/40 thus we have to return at lower lim check to ensure getting correct values.
  const firstRatio = aspect_ratio(width / height, 10);
  const firstAspectRatio = `${firstRatio[0]}:${firstRatio[1]}`;
  //check to see if this is custom ratio if yes then do the check again at high lim and return whatever comes
  if (!isCustomAspectRatio(firstAspectRatio)) {
    return firstAspectRatio;
  }
  const ratio = aspect_ratio(width / height, 65);
  return `${ratio[0]}:${ratio[1]}`;
}

export function calculateLayerDimensions({
  aspectRatio = "16:9",
  layerRes = 1080,
}) {
  let res;
  //eslint-disable-next-line
  try {
    res = aspectRatio.split(":");
  } catch (e) {
    throw e;
  }

  const resolution = {};
  const fraction = parseInt(res[0]) / parseInt(res[1]);

  if (parseInt(res[0]) > parseInt(res[1])) {
    resolution.height = layerRes;
    resolution.width = fraction * layerRes;
  } else if (parseInt(res[0]) < parseInt(res[1])) {
    resolution.height = layerRes / fraction;
    resolution.width = layerRes;
  } else {
    resolution.height = layerRes;
    resolution.width = layerRes;
  }
  resolution.height = Math.floor(resolution.height);
  resolution.width = Math.floor(resolution.width);
  resolution.height = resolution.height - (resolution.height % 2);
  resolution.width = resolution.width - (resolution.width % 2);

  return resolution;
}

export const calculateLayerQuality = (width, height) => {
  //calculate the min of both width and height and return the layer quality
  const min = Math.min(width, height);
  if (isEmpty(VIDEO_QUALITY_FOR_POLICY[min])) {
    return {};
  } else {
    return VIDEO_QUALITY_FOR_POLICY[min];
  }
};
export const getScreenShareLayer = screen => {
  const { width, height } = screen;
  let layer = {};
  //write a function to check width or height which matches ScreenShareQuality object height and width property and store it in layer variable
  if (width && height) {
    Object.values(ScreenShareQuality).forEach(quality => {
      if (quality.height === height && quality.width === width) {
        layer = quality;
      }
    });
  }
  return layer;
};

export const saveWorkspaceIDtoLocal = data => {
  localStorage.setItem("workspaceIDs", JSON.stringify(data));
};

export const getWorkspaceIDsfromLocal = () => {
  return JSON.parse(localStorage.getItem("workspaceIDs")) || {};
};

export const getEmailfromLocal = () => {
  const cookieKey = createCookieKey();
  const userData = JSON.parse(localStorage.getItem(cookieKey) || {});
  return userData?.email;
};

export function setLastAccessedWorkspaceID(email, workspaceID) {
  const workspaceIDs = getWorkspaceIDsfromLocal();
  workspaceIDs[email] = workspaceID;
  saveWorkspaceIDtoLocal(workspaceIDs);
}

export function getLastAccessedWorkspaceID(email) {
  const workspaceIDs = getWorkspaceIDsfromLocal();
  return workspaceIDs?.[email] || "";
}

export function getCurrentWorkspaceID() {
  const userCookie = currentUser();
  if (isAdmin()) {
    return userCookie?.customer?.workspace_id || "";
  }
  return userCookie?.workspaceID || "";
}

export const getMaxHeight = (
  numOfTemplates,
  limitTemplates,
  templateDropdownOpen,
  heightPerTemplate
) => {
  if (templateDropdownOpen === false) {
    return "0";
  }
  if (limitTemplates) {
    return `${7 * heightPerTemplate}px`;
  }
  return `${numOfTemplates * heightPerTemplate}px`;
};

export const getTopIndex = arr => {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (Math.floor(arr[i].getBoundingClientRect().top) < 200) {
      return i;
    }
  }
  return -1;
};
export const getInfoCard = cardTitle => {
  const value = localStorage.getItem(`${cardTitle}-info-card`);
  return !value;
};

export const setInfoCard = (cardTitle, val) => {
  localStorage.setItem(`${cardTitle}-info-card`, val);
};

export const bulletifyString = str => {
  if (str) {
    return `${str} ft&bull; `;
  } else {
    return "";
  }
};

export const getJoinRoomTooltipMessage = (
  template = {},
  subdomain = "",
  isRoomActive = false
) => {
  if (Object.keys(template).length === 0) {
    return "Template for the room has been deleted";
  }
  if (subdomain === "") {
    return "There is no subdomain attached to this room";
  }
  if (!isRoomActive) {
    return "The room has been disabled";
  }
  return "";
};

export const isCustomVideoQuality = res => {
  const videoQualityKeys = Object.keys(VideoQuality);
  const aspectRatioKeys = Object.keys(VideoQuality["_720p"]);
  const minDim = Math.min(res.width, res.height);
  const minDimKey = `_${minDim}p`;
  if (!videoQualityKeys.includes(minDimKey)) {
    return true;
  }

  // check whether the video quality object has the same width and height
  // return false if yes else return true
  for (let i = 0; i < aspectRatioKeys.length; i++) {
    if (
      VideoQuality[minDimKey][aspectRatioKeys[i]].width === res.width &&
      VideoQuality[minDimKey][aspectRatioKeys[i]].height === res.height
    ) {
      return false;
    }
  }
  return true;
};

export const sortLayers = layers => {
  const sortedLayers = layers.sort((layerA, layerB) => {
    if (layerA?.width !== layerB?.width) {
      return layerB?.width - layerA?.width;
    } else if (layerA?.height !== layerB?.height) {
      return layerB?.height - layerA?.height;
    } else if (layerA?.videoBitrate !== layerB?.videoBitrate) {
      return layerB?.videoBitrate - layerA?.videoBitrate;
    } else {
      return layerB?.audioBitrate - layerA?.audioBitrate;
    }
  });
  return sortedLayers;
};

export const isValidImageUrl = url => {
  const imageExtensions = /\.(jpeg|jpg|gif|png|bmp|svg)$/i;
  return url ? imageExtensions.test(url) : true;
};
export const isCustomHLSVideoLayer = layer => {
  for (const config of HLS_DEFAULT_VIDEO_LAYER_CONFIG) {
    if (
      config.value === Math.min(layer.height, layer.width) &&
      config.defaultAudioBitrate === layer.audioBitrate &&
      layer.videoBitrate === config.defaultVideoBitrate
    ) {
      return false;
    }
  }
  return true;
};

export const getPaletteArray = (
  paletteObject = {},
  themeType = Theme_ThemeType.THEME_TYPE_DARK
) => {
  const updatedPaletteObject = {
    ...defaultLayoutColorPalette,
    ...paletteObject,
  };

  const paletteArray =
    themeType === Theme_ThemeType.THEME_TYPE_LIGHT
      ? cloneDeep(defaultLightColorPalette)
      : cloneDeep(defaultDarkColorPalette);
  paletteArray.forEach(palette => {
    let colorKey = palette.key;
    palette.colorPalette.forEach(colorVariant => {
      let variantKey = `${colorKey}_${colorVariant.key}`;
      if (variantKey.includes("default")) {
        palette.value = updatedPaletteObject?.[variantKey];
      }
    });
  });

  return paletteArray;
};

export const getPaletteObject = (
  paletteArray = [],
  themeType = Theme_ThemeType.THEME_TYPE_DARK
) => {
  const paletteObject = {};
  paletteArray.forEach(palette => {
    let colorKey = palette.key;
    const aRGBofColor = argbFromHex(palette.value);
    const tonalPaletteOfArgb = TonalPalette.fromInt(aRGBofColor);
    const weightedPalette = analyseColorPalette(palette);
    const generatedPalette = weightedPalette.colorPalette.map(c =>
      hexFromArgb(tonalPaletteOfArgb.getHct(c.tone).toInt())
    );

    weightedPalette.colorPalette.forEach((colorVariant, index) => {
      let variantKey = `${colorKey}_${colorVariant.key}`.toLowerCase();
      if (colorVariant.key === "default") {
        paletteObject[variantKey] = palette.value;
      } else {
        paletteObject[variantKey] = generatedPalette[index];
      }
    });
    if (palette?.textColorPalette?.length > 0) {
      const generatedTextColorPalette = palette.textColorPalette.map(c =>
        hexFromArgb(tonalPaletteOfArgb.getHct(c.tone).toInt())
      );
      palette.textColorPalette.forEach((colorVariant, index) => {
        let variantKey = `on_${colorKey}_${colorVariant.key}`.toLowerCase();
        paletteObject[variantKey] = generatedTextColorPalette[index];
      });
    }
  });

  const hardcodedColorTokens = getHarcodedColorTokens(themeType);
  return { ...paletteObject, ...hardcodedColorTokens };
};
export const defaultHLSVideoLayers = HLS_DEFAULT_VIDEO_LAYER_CONFIG.filter(
  e => e.default
).map(layer => createLayerObject(layer, DEFAULT_ASPECT_RATIO));

const getHarcodedColorTokens = (
  themeType = Theme_ThemeType.THEME_TYPE_DARK
) => {
  const hardcodedColorTokens = {};
  const paletteObject =
    themeType === Theme_ThemeType.THEME_TYPE_DARK
      ? defaultLayoutColorPalette
      : defaultLightLayoutColorPalette;

  const hardcodedKeys = [
    "alert_error_default",
    "alert_error_bright",
    "alert_error_brighter",
    "alert_error_dim",
    "alert_success",
    "alert_warning",
  ];
  for (let i = 0; i < hardcodedKeys.length; i++) {
    hardcodedColorTokens[hardcodedKeys[i]] = paletteObject[hardcodedKeys[i]];
  }

  return hardcodedColorTokens;
};

export const middleTruncateString = (inputString, prefixLength) => {
  if (inputString.length > 2 * prefixLength) {
    const truncatedString = `${inputString.substring(
      0,
      prefixLength
    )}...${inputString.slice(-prefixLength)}`;
    return truncatedString;
  }
  return inputString;
};
